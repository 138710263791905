// package: service
// file: public_blog_post_comment.proto

var public_blog_post_comment_pb = require("./public_blog_post_comment_pb");
var common_pb = require("./common_pb");
var entity_blog_post_comment_pb = require("./entity_blog_post_comment_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PublicBlogPostCommentQuery = (function () {
  function PublicBlogPostCommentQuery() {}
  PublicBlogPostCommentQuery.serviceName = "service.PublicBlogPostCommentQuery";
  return PublicBlogPostCommentQuery;
}());

PublicBlogPostCommentQuery.GetAllByPost = {
  methodName: "GetAllByPost",
  service: PublicBlogPostCommentQuery,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.PostID,
  responseType: entity_blog_post_comment_pb.BlogPostCommentList
};

exports.PublicBlogPostCommentQuery = PublicBlogPostCommentQuery;

function PublicBlogPostCommentQueryClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PublicBlogPostCommentQueryClient.prototype.getAllByPost = function getAllByPost(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicBlogPostCommentQuery.GetAllByPost, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PublicBlogPostCommentQueryClient = PublicBlogPostCommentQueryClient;

var PublicBlogPostCommentCommand = (function () {
  function PublicBlogPostCommentCommand() {}
  PublicBlogPostCommentCommand.serviceName = "service.PublicBlogPostCommentCommand";
  return PublicBlogPostCommentCommand;
}());

PublicBlogPostCommentCommand.Create = {
  methodName: "Create",
  service: PublicBlogPostCommentCommand,
  requestStream: false,
  responseStream: false,
  requestType: public_blog_post_comment_pb.CreateBlogPostCommentRequest,
  responseType: common_pb.Empty
};

PublicBlogPostCommentCommand.Update = {
  methodName: "Update",
  service: PublicBlogPostCommentCommand,
  requestStream: false,
  responseStream: false,
  requestType: public_blog_post_comment_pb.UpdateBlogPostCommentRequest,
  responseType: common_pb.Empty
};

PublicBlogPostCommentCommand.Delete = {
  methodName: "Delete",
  service: PublicBlogPostCommentCommand,
  requestStream: false,
  responseStream: false,
  requestType: public_blog_post_comment_pb.DeleteBlogPostCommentRequest,
  responseType: common_pb.Empty
};

exports.PublicBlogPostCommentCommand = PublicBlogPostCommentCommand;

function PublicBlogPostCommentCommandClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PublicBlogPostCommentCommandClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicBlogPostCommentCommand.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicBlogPostCommentCommandClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicBlogPostCommentCommand.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicBlogPostCommentCommandClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicBlogPostCommentCommand.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PublicBlogPostCommentCommandClient = PublicBlogPostCommentCommandClient;

