import {
  CreateBlogPostCommentRequest,
  UpdateBlogPostCommentRequest,
  DeleteBlogPostCommentRequest,
} from "../infra/api/pb/public_blog_post_comment_pb";
import {
  PublicBlogPostCommentCommand,
  PublicBlogPostCommentCommandCreate,
  PublicBlogPostCommentCommandDelete,
  PublicBlogPostCommentCommandUpdate,
  PublicBlogPostCommentQuery,
  PublicBlogPostCommentQueryGetAllByPost,
} from "../infra/api/pb/public_blog_post_comment_pb_service";
import { GRPCUnary } from "../infra/api/client";
import { Empty, PostID } from "../infra/api/pb/common_pb";
import {
  BlogPostComment,
  BlogPostCommentList,
} from "../infra/api/pb/entity_blog_post_comment_pb";
import * as firebase from "firebase/app";
import "firebase/auth";

interface ICreate {
  params: CreateBlogPostCommentRequest;
}

interface IUpdate {
  params: UpdateBlogPostCommentRequest;
}

interface IDelete {
  params: DeleteBlogPostCommentRequest;
}

interface IBlogPostCommentHooks {
  getAllByPost: (postId: string) => Promise<BlogPostCommentList>;

  createBlogPostComment: (req: ICreate) => Promise<void>;
  updateBlogPostComment: (req: IUpdate) => Promise<void>;
  deleteBlogPostComment: (req: IDelete) => Promise<void>;
}

const useBlogPostCommentHooks = (): IBlogPostCommentHooks => {
  const getAllByPost = async (postId: string) => {
    try {
      const req: PostID = new PostID();
      req.setId(postId);
      const res = await GRPCUnary<
        PostID,
        BlogPostCommentList,
        PublicBlogPostCommentQueryGetAllByPost
      >({
        method: PublicBlogPostCommentQuery.GetAllByPost,
        request: req,
      });

      return res;
    } catch (e) {
      throw e;
    }
  };

  const createBlogPostComment = async (req: ICreate) => {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();

      await GRPCUnary<
        CreateBlogPostCommentRequest,
        Empty,
        PublicBlogPostCommentCommandCreate
      >({
        method: PublicBlogPostCommentCommand.Create,
        token: token,
        request: req.params,
      });
    } catch (e) {
      throw e;
    }
  };

  const updateBlogPostComment = async (req: IUpdate) => {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();

      await GRPCUnary<
        UpdateBlogPostCommentRequest,
        Empty,
        PublicBlogPostCommentCommandUpdate
      >({
        method: PublicBlogPostCommentCommand.Update,
        token: token,
        request: req.params,
      });
    } catch (e) {
      throw e;
    }
  };

  const deleteBlogPostComment = async (req: IDelete) => {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();

      await GRPCUnary<
        DeleteBlogPostCommentRequest,
        Empty,
        PublicBlogPostCommentCommandDelete
      >({
        method: PublicBlogPostCommentCommand.Delete,
        token: token,
        request: req.params,
      });
    } catch (e) {
      throw e;
    }
  };

  return {
    getAllByPost,
    createBlogPostComment,
    updateBlogPostComment,
    deleteBlogPostComment,
  };
};

export { useBlogPostCommentHooks };
