import * as React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { BlogPostPageQuery } from "../../types/graphql-types";
import Layout from "../components/Layout";
import { theme } from "../components/theme";
import MarkdownViewer from "../components/MarkDownViwer";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAuthHooks } from "../hooks/auth_hooks";
import { useBlogPostCommentHooks } from "../hooks/blog_post_comment_hooks";
import { AuthContext } from "../context/auth_context";
import { BlogPostCommentList } from "../infra/api/pb/entity_blog_post_comment_pb";
import { Log } from "../util/Logger";
import {
  CreateBlogPostCommentRequest,
  DeleteBlogPostCommentRequest,
} from "../infra/api/pb/public_blog_post_comment_pb";
dayjs.extend(relativeTime);

const removeMd = require("remove-markdown");

type FormType = {
  comment: string;
};

interface BlogPostProps {
  data: BlogPostPageQuery;
  pageContext: {
    contentful_id: string;
    next: {
      contentful_id: string;
      title: string;
    };
    previous: {
      contentful_id: string;
      title: string;
    };
  };
}

const Activity: React.FC<BlogPostProps> = ({ data, pageContext }) => {
  const blogPost = data.allContentfulBlogPost.edges[0].node;
  const postId = blogPost.contentful_id;

  const [blogPostCommentList, setBlogPostCommentList] = useState<
    BlogPostCommentList
  >();

  const { loginGithub, logout } = useAuthHooks();
  const {
    getAllByPost,
    createBlogPostComment,
    updateBlogPostComment,
    deleteBlogPostComment,
  } = useBlogPostCommentHooks();

  const { user } = useContext(AuthContext);

  const initData = async () => {
    try {
      const raw = await getAllByPost(postId);
      setBlogPostCommentList(raw);
    } catch (e) {
      Log.captureException(e);
    }
  };

  const { register, handleSubmit, watch, errors, setValue, reset } = useForm<
    FormType
  >();

  const onSubmit = async (data: FormType, e: any) => {
    const params = new CreateBlogPostCommentRequest();
    params.setPostId(postId);
    params.setBody(data.comment);
    params.setUsername(user.email);
    await createBlogPostComment({ params });
    await initData();
    reset();
  };

  const handlerOnLoginGithub = async () => {
    try {
      await loginGithub();
    } catch (e) {
      Log.captureException(e);
    }
  };

  const handlerOnDelete = async (id: string) => {
    try {
      const params = new DeleteBlogPostCommentRequest();
      params.setId(id);
      await deleteBlogPostComment({ params });
      await initData();
    } catch (e) {
      Log.captureException(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await initData();
      } catch (e) {
        Log.captureException(e);
      }
    })();
  }, [postId]);

  return (
    <Layout>
      <Helmet>
        <title>{blogPost.title}</title>
      </Helmet>

      <Container>
        <Title>{blogPost.title}</Title>
        <EntryHeaderMeta>
          <Time>{blogPost.postedAt}</Time>
          <ByLine> / </ByLine>
          <UpperCaseAutherName>{blogPost.refAuthor.name}</UpperCaseAutherName>
        </EntryHeaderMeta>
        <HeroImage src={blogPost.heroImage.file.url} />
        <Body>
          <MarkdownViewer children={blogPost.body.body} />
        </Body>

        <BottomContent>
          {blogPost.refCategories.map((refCategory) => (
            <CategoryBadge key={refCategory.name}>
              {refCategory.name}
            </CategoryBadge>
          ))}

          <AuthorWrapper>
            <AuthorAvator src={blogPost.refAuthor.avatorImage.file.url} />
            <div>
              <Writter>WRITTEN BY</Writter>
              <AuthorName>{blogPost.refAuthor.name}</AuthorName>
            </div>
          </AuthorWrapper>
          <div>
            <h2>関連記事</h2>
            {blogPost.refBlogPosts &&
              blogPost.refBlogPosts.map((refBlogPost) => {
                return (
                  <RelatedBlogPostWrapper
                    to={`/blogPost/${refBlogPost.contentful_id}`}
                    key={refBlogPost.contentful_id}
                  >
                    <RelatedBlogPostHeroImage
                      src={refBlogPost.heroImage.file.url}
                    />
                    <RelatedBlogPostContent>
                      <RelatedBlogPostTitle>
                        {refBlogPost.title}
                      </RelatedBlogPostTitle>
                      <RelatedBlogPostBody>
                        {refBlogPost.overView
                          ? refBlogPost.overView
                          : removeMd(refBlogPost.body.body)}
                      </RelatedBlogPostBody>
                    </RelatedBlogPostContent>
                  </RelatedBlogPostWrapper>
                );
              })}
          </div>
        </BottomContent>

        <CommentContent>
          <h2>コメント</h2>

          {!user && (
            <CommentAuthContainer>
              <LoginButton onClick={() => handlerOnLoginGithub()}>
                Login
              </LoginButton>
            </CommentAuthContainer>
          )}
          {user && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Textarea name="comment" ref={register} />
              <FlexLeftWrapper>
                <CommentButton
                  id={`button-add-blog-post-comment`}
                  type="submit"
                >
                  AddComment
                </CommentButton>
              </FlexLeftWrapper>
            </form>
          )}
          <div>
            {blogPostCommentList &&
              blogPostCommentList.getItemsList().map((comment) => {
                return (
                  <CommentWrapper key={comment.getId()}>
                    <NameAndDaysWrapper>
                      <CommentName>{comment.getUsername()}</CommentName>
                      <CommentCreatedDay>
                        {dayjs().to(dayjs(comment.getCreatedat()))}
                      </CommentCreatedDay>
                    </NameAndDaysWrapper>
                    <CommentBody>
                      <MarkdownViewer children={comment.getBody()} />
                    </CommentBody>

                    {user && user.id === comment.getUserid() && (
                      <CommentFooter>
                        <CommentButton
                          id={`button-delete-blog-post-comment`}
                          onClick={() => handlerOnDelete(comment.getId())}
                        >
                          Delete
                        </CommentButton>
                      </CommentFooter>
                    )}
                  </CommentWrapper>
                );
              })}
          </div>
        </CommentContent>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostPage($contentful_id: String) {
    allContentfulBlogPost(filter: { contentful_id: { eq: $contentful_id } }) {
      edges {
        node {
          contentful_id
          heroImage {
            file {
              url
            }
          }
          title
          refAuthor {
            avatorImage {
              file {
                url
              }
            }
            name
          }
          refCategories {
            name
          }
          postedAt(formatString: "YYYY年MM月DD日")
          body {
            body
          }
          refBlogPosts {
            heroImage {
              file {
                url
              }
            }
            body {
              body
            }
            contentful_id
            overView
            title
          }
        }
      }
    }
  }
`;

export default Activity;

const Container = styled.div`
  color: #000000;
  width: 100vw;
  padding: 0 10px;
  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 0 auto;
    padding: 40px 15px 80px;
    width: ${theme.width.blogPost};
  }
  * {
    box-sizing: border-box;
  }
`;

const Title = styled.h1`
  font-size: 21px;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const EntryHeaderMeta = styled.div`
  margin-bottom: 36px;
  text-transform: uppercase;
`;

const Time = styled.time`
  color: #999;
`;

const ByLine = styled.span`
  color: #999;
  margin: 0 8px;
`;

const UpperCaseAutherName = styled.span`
  color: #999;
  text-transform: uppercase;
`;

const HeroImage = styled.img`
  width: 100%;
`;

const Body = styled.div`
  padding: 0 ${theme.width.paddingSideSP};
  width: 100vw;
  img {
    max-width: calc(100vw - 20px);
  }
  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 0;
    width: ${theme.width.blogPost};
    img {
      max-width: ${theme.width.blogPost};
    }
  }
`;

const CategoryBadge = styled.span`
  padding: 3px 6px;
  margin-right: 8px;
  margin-left: 1px;
  font-size: 75%;
  color: ${theme.colors.black};
  background-color: ${theme.colors.grey20};
  border-radius: 6px;
  box-shadow: 0 0 3px #ddd;
  white-space: nowrap;
`;

const BottomContent = styled.div`
  margin-top: 40px;
`;

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  margin-bottom: 25px;
`;
const AuthorAvator = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 10px;
`;
const Writter = styled.p`
  margin: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.54);
`;
const AuthorName = styled.h2`
  line-height: 36px;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
`;

const RelatedBlogPostWrapper = styled(Link)`
  display: flex;
  margin-bottom: 20px;
  text-decoration: none;
  box-sizing: border-box;
  width: 100%;
`;

const RelatedBlogPostHeroImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 10px;
`;

const RelatedBlogPostContent = styled.div``;

const RelatedBlogPostTitle = styled.h3`
  color: #333;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
`;

const RelatedBlogPostBody = styled.p`
  margin: 0 0 5px 0;
  color: #555;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const RelatedBlogPostLink = styled.a`
  text-decoration: none;
`;

const CommentContent = styled.div`
  margin-top: 40px;
`;

const Textarea = styled.textarea`
  display: inline-block;
  white-space: pre-wrap;
  padding: 8px;
  outline: 0;
  overflow: auto;
  min-height: 130px;
  width: 100%;
`;

const FlexLeftWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CommentButton = styled.button`
  background: #1971c2;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  padding: 6px 8px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
`;

const CommentWrapper = styled.div`
  padding: 12px 0 0 12px;
  margin-top: 16px;
  border-top: 1px solid #f0f0f0;

  border-left: 2px solid rgb(57, 106, 177);
`;

const CommentBody = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 48px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const NameAndDaysWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CommentName = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: #555;
  border: none;
  display: table;
  z-index: 1;
  /* margin-left: 48px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  /* cursor: pointer; */
`;

const CommentCreatedDay = styled.div`
  display: inline;
  color: #888;
  font-size: 12px;
`;

const CommentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CommentAuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const LoginButton = styled.div`
  text-align: right;
  margin-right: 16px;
  height: 38px;
  color: #868e96;
  font-weight: 700;
  cursor: pointer;
  display: block;
`;
